const protocoloService = {
  ws: "wss",
  http: ""
}
export const environment = {
  name: "service  desarrollo produccion",
  host: "localhost",
  port: "8000",
  protocolo: "http",
  headers: {
    Fiat: "ecs-fiat",
    Incode: "ecs-incode",
    Library: "ecs-library",
    Operations: "ecs-operations",
    Orchestrator: "ecs-orchestrator",
    Prints: "ecs-prints",
    Users: "ecs-users",
    Vankorchestrator: "ecs-vankorchestrator"
  },
  url_servers: {
    url_orchertractor: "https://alb.thisisvank.com/orchestrator",
    url_users: "https://alb.thisisvank.com/users",
    url_library: "https://alb.thisisvank.com/library",
    url_operation: "https://alb.thisisvank.com/operations/secure/v1",
    url_usersAWS: "https://alb.thisisvank.com/users",
    url_prints: "https://alb.thisisvank.com/prints",
    url_fiat: "https://alb.thisisvank.com/fiatServices",
    url_upload_excel: "",
    url_batch_template: "https://templatesvank.s3.us-west-2.amazonaws.com"
  },
  url_externas: {
    url_realTimeValue: "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd",
    url_binance: "https://alb.thisisvank.com/binanceorchestrator",
    url_incode: "https://alb.thisisvank.com/incode",
  },
  url_socket: {
    url_vank_orchestractor: `${protocoloService.ws}://vankorchestratorprod.thisisvank.com:3027`,
    url_operations: `${protocoloService.ws}://operationsprod.thisisvank.com:3023`
  }
};