import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

type RouteError = {
  statusText?: string;
  message?: string;
};

const ErrorLayout = () => {
  const error = useRouteError() as RouteError | null;
  console.log(error);

  return (
    <div
      id="error-page"
      className="flex flex-col gap-8 justify-center items-center h-screen"
    >
      <h1 className="text-4xl font-bold dark:text-[#FFF]">Oops!</h1>
      <p className="text-[#2D2E2F] dark:text-[#FFF]">Sorry, an unexpected error has occurred.</p>
      <p className="text-[#818282] dark:text-[#FFF]">
        <i>{error.message}</i>
      </p>
    </div>
  );
};

export default ErrorLayout;
